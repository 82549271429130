
$(function(){
  $('.top-keyvisual-slider').slick({
    autoplay:true,
    speed:1000,
    autoplaySpeed:5000,
    arrows: false,
    dots: false,
    fade:true,
    pauseOnFocus: false,
    pauseOnHover: false,
  });
  
});
